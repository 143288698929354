<template lang="pug">
main.listingPage
  Catalog(allFilters)
  Contact
  DrawLine._y._1
  DrawLine._x._fullWidth._2
  DrawLine._x._fullWidth._3
</template>

<script>
  import Catalog from '@/components/Catalog';
  import Contact from '@/components/Contact';
  import DrawLine from '@/components/DrawLine';

  export default {
    components: {
      Catalog,
      Contact,
      DrawLine,
    },
    mounted() {
      // Draws
      // NOT DRY on SinglePage
      const desktop = window.matchMedia('(min-width: 769px)').matches;
      const windowHeight = document.documentElement.clientHeight;
      const halfWindowHeight = document.documentElement.clientHeight / 2;
      const verticalTrigger = desktop
        ? halfWindowHeight + halfWindowHeight / 2
        : halfWindowHeight + windowHeight / 3;
      function documentScrolled() {
        YDrawElements.forEach((el, i) => {
          if (el.getBoundingClientRect().top < verticalTrigger) {
            el.classList.add('_active');
            if (i == YDrawElements.length - 1) {
              window.removeEventListener('scroll', documentScrolled);
              console.log('documentScrolled clear');
            }
          }
        });
      }

      const YDrawElements = [...document.querySelectorAll('.drawLine')];
      window.addEventListener('scroll', documentScrolled);
      documentScrolled();
    },
  };
</script>

<style lang="scss">
  .listingPage {
    .drawLine {
      &._1 {
        grid-row: 6;
        grid-column: 9;
        @include tablet {
          display: none;
        }
      }
      &._2 {
        grid-row: 7;
        @include tablet {
          grid-row: 9;
        }
      }
      &._3 {
        grid-row: 8;
        margin-top: -2px;

        @include tablet {
          grid-row: 10;
        }
      }
    }
  }
</style>
